@import 'Styles/breakpoints.module.scss';

.productCard {
  width: 100%;
}

.debugCard {
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    gap: 0.5rem;
  }
}

.card {
  padding: 0.5rem;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.display_flex {
  display: flex;
}
